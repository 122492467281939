<template>
  <div>
    <div class="container vh-100">
      <div class="row vh-100 justify-content-center align-items-center">
        <form class="col-12">
          <img
            src="../assets/not-found.svg"
            class="img-fluid mt-5 mx-auto d-block"
            alt=""
          />

          <p class="lead font-weight-lighter text-light text-center mt-5">
            <span>Uh? Who cut the Volume?</span> <br />
            <span class="font-weight-bold">404 not found</span> <br />
            <a
              href="mailto:support@trackdmusic.com"
              class="font-weight-lighter text-light mt-0 pt-0 text-underline"
              >Contact Support</a
            >
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
  computed: {},
};
</script>

<style scoped>
body,
html {
  height: 100%;
}
</style>
